import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline, mdiCogOutline, mdiDatabaseOutline, mdiDnsOutline, mdiPencilOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useOverdueOrder() {
    const headers = [
        { text: '订单类型', value: 'order_type' },
        { text: '用户', value: 'username', sortable: false },
        { text: '用户手机', value: 'mobile', sortable: false },
        { text: '司机', value: 'driver_name', sortable: false },
        { text: '司机手机', value: 'driver_mobile', sortable: false },
        { text: '车辆', value: 'car_no' },
        { text: '出发地', value: 'start_address' },
        { text: '纬度', value: 'start_lat' },
        { text: '经度', value: 'start_lng' },
        { text: '目的地', value: 'end_address' },
        { text: '纬度', value: 'end_lat' },
        { text: '经度', value: 'end_lng' },
        { text: '状态', value: 'tate' },
        { text: '申请时间', value: 'create_at' },
        // { text: '操作', value: 'actions', align: 'center', sortable: false }
    ]
    const param = ref({
        query: '',
        options: { sortBy: ['id'], sortDesc: [true], page: 1, itemsPerPage: 10 },
        state: "30", // -1表示已分配，非99状态
    })
    const table = ref({
        list: [],
        total: 0,
        snak: {
            open: false,
            msg: ''
        },
        lodading: false,
        genders: {
            1: "男",
            2: "女",
        },
        authTypes: {
            MAN_USER_MOBILE: '人工认证'
        },
        authStatus: {
            1: "成功",
            2: "失败",
            3: "待审核",
            4: "待短信验证",
        },
        dialogRefuse: {
            open: false,
            item: { id: '', nickname: '', real_name: '', failed_reason: '' }
        }
    })
    const fetchOverdueOrderList = () => {
        table.value.loading = true
        store.dispatch('order-taxi-all/fetchOverdueOrderList', param.value).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snak = {
                    open: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            const { list, total } = res.data.data
            table.value.list = list || []
            table.value.total = total
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }

    const applyAccept = (item) => {
        table.value.loading = true
        store.dispatch('order-taxi-all/fetchOverdueOrderAccept', item).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snak = {
                    open: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            fetchOverdueOrderList()
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }

    const applyRefuse = () => {
        table.value.loading = true
        store.dispatch('order-taxi-all/fetchOverdueOrderRefuse', table.value.dialogRefuse.item).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snak = {
                    open: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            table.value.dialogRefuse.open = false
            fetchOverdueOrderList()
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }

    watch([param], () => {
        fetchOverdueOrderList()
    }, { deep: true })
    return {
        headers,
        param,
        table,
        fetchOverdueOrderList, applyAccept, applyRefuse
    }
}
