<template>
    <div id='user-list'>
        <v-dialog v-model="table.dialogRefuse.open" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">审核拒绝</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogRefuse.item.nickname" label="昵称" dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field readonly v-model="table.dialogRefuse.item.real_name" label="真实姓名" dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field v-model="table.dialogRefuse.item.failed_reason" label="拒绝" dense hint="请输入审核不通过的原因"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text> <v-card-actions> <v-spacer></v-spacer> <v-btn color="error" outlined @click="table.dialogRefuse.open = false"> 取消 </v-btn> <v-btn color="success" @click="applyRefuse()">
                        提交 </v-btn> </v-card-actions> </v-card>
        </v-dialog>
        <v-snackbar v-model="table.snak.open" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ table.snak.msg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="table.snak.open = false"> 关闭 </v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-divider class='mt-4'></v-divider>
            <v-card-text class='d-flex align-center flex-wrap pb-0'>
                <v-text-field v-model='param.query' class='user-search me-3 mb-4' dense hide-details outlined placeholder='搜索过期未分配订单'></v-text-field>
                <v-col cols='12' sm='8' class=" mb-4">
                    <date-picker placeholder="下单开始时间" v-model="param.start_at" type="datetime" :default-value="formatDateTime(new Date())"></date-picker>
                    <date-picker class="ml-2" placeholder="下单结束时间" v-model="param.end_at" type="datetime" :default-value="formatDateTime(new Date())"></date-picker>
                </v-col>
                <v-spacer></v-spacer>
            </v-card-text>
            <!-- table -->
            <v-data-table :headers='headers' :items='table.list' :loading='table.loading' :options.sync='param.options' :server-items-length='table.total' show-select>
                <template #[`item.auth_type`]='{ item }'>
                    {{ table.authTypes[item.auth_type] || '-' }}
                </template>
                <template #[`item.auth_state`]='{ item }'>
                    {{ table.authStatus[item.auth_state] || '-' }}
                </template>
                <template #[`item.gender`]='{ item }'>
                    {{ table.genders[item.gender] || '-' }}
                </template>
                <!-- actions -->
                <template #[`item.actions`]='{ item }'>
                    <v-row>
                        <v-col v-if="item.auth_state == 3">
                            <v-btn @click="applyAccept(item)" color="primary" dark>通过
                                <v-icon dark right> {{ icons.mdiCheckboxMarkedCircleOutline }} </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col v-if="item.auth_state == 3">
                            <v-btn @click="toRefuse(item)" color="error" dark>拒绝
                                <v-icon dark right> {{ icons.mdiCancel }} </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiPencil, mdiPlus, mdiCancel, mdiSquareEditOutline, mdiCheckboxMarkedCircleOutline } from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import orderStoreModule from '../orderStoreModule'
import useOverdueOrderList from './useOverdueOrder'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';

export default {
    components: { DatePicker }, setup() {
        const USER_APP_STORE_MODULE_NAME = 'order-taxi-all'
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, orderStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const { headers, param, table, fetchOverdueOrderList, applyAccept, applyRefuse } = useOverdueOrderList()

        fetchOverdueOrderList()

        const toRefuse = (item) => {
            table.value.dialogRefuse = {
                open: true,
                item: item
            }
        }

        const pad = (num) => {
            return num.toString().padStart(2, '0');
        }
        const formatDateTime = (date) => {
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hour = date.getHours();
            const minute = date.getMinutes();
            const second = date.getSeconds();
            return `${year}-${pad(month)}-${pad(day)} ${pad(hour)}:${pad(minute)}:${pad(second)}`;
        }
        return {
            headers, formatDateTime,
            param,
            table,
            fetchOverdueOrderList, applyAccept, applyRefuse,
            toRefuse,
            // icons
            icons: { mdiSquareEditOutline, mdiPencil, mdiDotsVertical, mdiDeleteOutline, mdiPlus, mdiCancel, mdiExportVariant, mdiAccountOutline, mdiCheckboxMarkedCircleOutline },
        }
    },
} </script>
<style lang='scss'>
@import '@core/preset/preset/apps/user.scss';
</style>
