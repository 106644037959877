import axios from '@axios'

export default {
    namespaced: true, state: {}, getters: {}, mutations: {}, actions: {

        fetchAllOrderList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/order/taxi/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        fetchWaitOrderList(ctx, queryParams) {

            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/order/taxi/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        fetchAllocatedOrderList(ctx, queryParams) {

            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/order/taxi/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        fetchOverdueOrderList(ctx, queryParams) {

            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/order/taxi/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
    },
}
